// --- Post bootstrap -----
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../modules/components/Typography';
import AppFooter from '../modules/views/AppFooter';
// import AppAppBar from '../modules/views/AppAppBar';
import AppForm from '../modules/views/AppForm';
import { email, required } from '../modules/form/validation';
import RFTextField from '../modules/form/RFTextField';
import FormButton from '../modules/form/FormButton';
import FormFeedback from '../modules/form/FormFeedback';

import Button from 'modules/components/Button';
// import { motion } from 'framer-motion';

import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { resendemail } from "apis/Auth";
import Box from '@material-ui/core/Box';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(6),
    },
    button: {
        // width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    feedback: {
        marginTop: theme.spacing(2),
    },
    emailIcon: {
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 20,
        // width: '100%',
        // height: 80,
        // backgroundColor: theme.palette.common.white,
        // align: 'center'
        // alignItmes: 'center'
        justifyContent: 'center'
        // justifyContent: 'center'
        // width: 200
    },
    avatar: {
        // paddingTop: 20,
        // display: 'flex',
        // width: '100%',
        // height: 80,
        backgroundColor: theme.palette.secondary.main,
        // align: 'center'
        // alignItmes: 'center'
        // justifyContent: 'center'
        // justifyContent: 'center'
        // width: 200
    },
    container: {
        // [theme.breakpoints.down('sm')]: {
        //     paddingLeft: theme.spacing(5),
        //     paddingRight: theme.spacing(5),
        // },
        // paddingLeft: theme.spacing(10),
        // paddingRight: theme.spacing(10),
        // marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(10),
        // display: 'flex',
        // position: 'relative',
    },
    text: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
}));

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        // scale: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
            opacity: { duration: 1.5 }
        }
    },
}

function ResendActMail(props) {
    const locale = useSelector(state => state.locale);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const classes = useStyles();
    const history = useHistory();
    const [sent, setSent] = React.useState(false);
    const [dis, setDis] = React.useState(false);

    const [successMsg, setSuccessMsg] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const validate = (values) => {
        const errors = required(['email'], values);

        if (!errors.email) {
            const emailError = email(values.email, values);
            if (emailError) {
                errors.email = email(values.email, values);
            }
        }

        return errors;
    };

    const handleSubmit = (values) => {
        setSent(true);
        resendemail({
            email: values.email,
            locale: locale
        })
            .then(res => {
                setSent(false);
                setSuccessMsg(res.data);
                setDis(true);
            })
            .catch(err => {
                setSent(false);
                setErrorMsg(err.response.data.errors);
            })
    };

    return (
        <React.Fragment>
            <AppForm className={classes.container}>
                <Box className={classes.emailIcon}>
                    <Avatar className={classes.avatar}>
                        <MailOutlineIcon />
                    </Avatar>
                </Box>
                <Typography variant="h4" align="center" className={classes.text}>
                    Verify your Account
                </Typography>
                <Form onSubmit={handleSubmit} subscription={{ submitting: true }} validate={validate}>
                    {({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className={classes.form} noValidate>
                            <Field
                                autoFocus
                                autoComplete="email"
                                component={RFTextField}
                                disabled={submitting || sent}
                                fullWidth
                                label={messages[locale].Email}
                                margin="normal"
                                name="email"
                                required
                                size="large"
                            />
                            {
                                successMsg ?
                                    <FormFeedback
                                        className={classes.feedback}
                                        success
                                    >
                                        {messages[locale].Suc[successMsg]}
                                    </FormFeedback> : null
                            }
                            {
                                errorMsg ?
                                    <FormFeedback
                                        className={classes.feedback}
                                        error
                                    >
                                        {messages[locale].Errs[errorMsg]}
                                    </FormFeedback> : null
                            }
                            <motion.div
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 0.9,
                                    rotate: 0,
                                }}
                                whileHover={{ scale: 1 }}
                            >
                                <FormButton
                                    className={classes.button}
                                    disabled={submitting || sent || dis}
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                >
                                    {messages[locale].Resend}
                                </FormButton>
                            </motion.div>
                        </form>
                    )}
                </Form>
            </AppForm>
            <AppFooter />
        </React.Fragment>
    );
}

export default ResendActMail;
export const UPLOAD_ADDRESS = 'UPLOAD_ADDRESS';
export const UPLOAD_TXID = 'UPLOAD_TXID';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const USER_LOGOUT = 'USER_LOGOUT';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';


// export function uploadAddress(address) {
//     return { type: UPLOAD_ADDRESS, payload: address }
// }

// export function uploadTxid(txid) {
//     return { type: UPLOAD_TXID, payload: txid }
// }

export function changeLocale(locale) {
    return { type: CHANGE_LOCALE, payload: locale }
}

export function isAuth(isAuthenticated) {
    return { type: IS_AUTHENTICATED, payload: isAuthenticated }
}

export function tokenUpdate(token) {
    return { type: UPDATE_TOKEN, payload: token }
}
export function emailUpdate(email) {
    return { type: UPDATE_USER_EMAIL, payload: email }
}

// export function logoutRedux() {
//     return { type: USER_LOGOUT }
// }
import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
// import { useMediaQuery } from '@material-ui/core';
import AppBar from 'modules/views/AppAppBar';
import AppFooter from 'modules/views/AppFooter';
// import { Sidebar, Topbar, Footer } from './items';

// const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: 72,
        // height: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     paddingTop: 64
        // }
    },
    shiftContent: {
        // paddingTop: 10,
        paddingLeft: 240
    },
    content: {
        margin: -4
        // width: '100%',
        // height: '100%'
    },
    block: {
        width: '100%',
        height: 45
    }
}))

const Main = props => {
    const { children } = props;
    const classes = useStyles();

    // const theme = useTheme();
    // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    //     defaultMatches: true
    // });

    // const [openSidebar, setOpenSidebar] = useState(false);

    // const handleSidebarOpen = () => {
    //     setOpenSidebar(true);
    // };
    // const handleSidebarClose = () => {
    //     setOpenSidebar(false);
    // };
    // const shouldOpenSidebar = isDesktop ? true : openSidebar;


    return (
        <div className={classes.content}>
            <AppBar />
            <main className={classes.content}>
                <div className={classes.block} />
                {children}
                {/* <AppFooter /> */}
            </main>
        </div>
    )
}

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const RouteWithLayout = props => {
    const { layout: Layout, component: Component, top: isTop, ...rest } = props;
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    return (
        <Route
            {...rest}
            render={matchProps => (!isAuthenticated | isTop ?
                <Layout>
                    <Component {...matchProps} />
                </Layout>
                :
                <Redirect to={{ pathname: "/home" }} />
            )}
        />
    );
}

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default RouteWithLayout;
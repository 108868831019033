import axios from 'axios';

var base_url = '';
if (process.env.NODE_ENV === 'development') {
    base_url = 'http://localhost:4000/api';
} else {
    base_url = '/api';
}
// const base_url = '/api';

// const base_url = 'http://www.greenguardians.org/api';

export const login = (userdata) => {
    return axios.post(`${base_url}/login`, userdata, { withCredentials: true })
}

export const signup = (userdata) => {
    return axios.post(`${base_url}/signup`, userdata, { withCredentials: true })
}

export const resetpw = (userdata) => {
    return axios.post(`${base_url}/resetpw`, userdata, { withCredentials: true })
}

export const logout = () => {
    return axios.get(`${base_url}/logout`, { withCredentials: true })
}

export const transactions = (userdata) => {
    return axios.post(`${base_url}/transactions`, userdata, { withCredentials: true })
}

export const userinfo = (userdata) => {
    return axios.post(`${base_url}/user`, userdata, { withCredentials: true })
}

export const usertrans = (userdata) => {
    return axios.post(`${base_url}/usertrans`, userdata, { withCredentials: true })
}

export const resendemail = (userdata) => {
    return axios.post(`${base_url}/resendemail`, userdata, { withCredentials: true })
}
export const resetpwact = (userdata) => {
    return axios.post(`${base_url}/resetpwaction`, userdata, { withCredentials: true })
}
import messages_en from './languages/en.json';
import messages_zh from './languages/zh.json';

export const messages = {
    'en-US': messages_en,
    'zh-CN': messages_zh
}

export const LANGUAGES = [
    {
        code: 'en-US',
        name: 'English',
        short: 'EN'
    },
    {
        code: 'zh-CN',
        name: '简体中文',
        short: '简中'
    }
];

export const language = navigator.language.split(/[-_]/)[0];
// --- Post bootstrap -----
import React from 'react';
import Paper from 'modules/components/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'modules/components/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Field, Form, FormSpy } from 'react-final-form';
import FormFeedback from 'modules/form/FormFeedback';
import RFTextField from 'modules/form/RFTextField';
import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';
import FormButton from 'modules/form/FormButton';
import { motion } from 'framer-motion';
import { txid, wallet, required } from 'modules/form/validation';
import { abi } from 'setups/abi';
import InputDataDecoder from 'ethereum-input-data-decoder';
import AlertDialog from 'modules/components/Dialog';
import { transaction } from 'apis/Eth';
import { transactions } from 'apis/Auth';
import { config } from 'setups/config';
// import VerticalTabs from 'modules/components/VerticalTabs';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            width: "95%",
            marginTop: 20,
        },
        display: 'flex',
        flexDirection: 'row',
        // maxWidth: 1000,
        width: "80%",
        maxHeight: '100vh',
        marginTop: 50,
        backgroundColor: '#fff',
        borderRadius: 5,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
    },
    right: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            marginLeft: 15
        },
        display: 'flex',
        flexDirection: 'column',
        // borderLeft: '3px solid #F5F5F5',
        paddingBottom: 50,
        // marginTop: 30,
        marginBottom: 30,
        paddingLeft: 50,
        // minWidth: 200
    },
    image: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            paddingTop: 60
        },
        height: 90,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 50
    },
    email: {
        paddingLeft: 20,
        paddingTop: 50,
        fontWeight: '600'
    },
    address: {
        paddingLeft: 20,
        paddingTop: 10,
        fontWeight: '600',
        color: theme.palette.secondary.main,
    },
    form: {
        width: '80%',
        // paddingLeft: 50,
        paddingTop: 30
    },
    welcome: {
        paddingTop: 60,
        paddingBottom: 30,
        // paddingLeft: 50,
        fontWeight: '600',
        fontSize: 24,
        color: theme.palette.secondary.main,
    },
    welcometext: {
        wordWrap: 'break-all',
        // paddingLeft: 50,
        color: '#707070',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    wallettext: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
        },
    },
    button: {
        marginTop: 20
    },
    down: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
        display: 'none',

    },
    // userinfo: {
    //     wordBreak: 'break-all',
    //     paddingBottom: theme.spacing(1),
    //     paddingTop: theme.spacing(1),
    // },
    userContainer: {
        width: '90%',
        // margin: theme.spacing(1),
    },
    error: {
        color: 'red'
    },
    box: {
        // display: 'flex',
        flexDirection: 'column',
        // width: '50%'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    spacegap: {
        marginBottom: 10
    }
}));

export default function Apply() {
    const classes = useStyles();
    const locale = useSelector(state => state.locale);
    const token = useSelector(state => state.user.token);

    const [userInfo, setUserInfo] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [disApply, setDisApply] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    // const [formDis, setFormDis] = React.useState(false);

    const [txidValue, setTxidValue] = React.useState(null);
    const [walletValue, setWalletValue] = React.useState(null);
    const [fromValue, setFromValue] = React.useState(null);
    const [toValue, setToValue] = React.useState(null);
    const [amount, setAmount] = React.useState(0);

    const [internetError, setInternetError] = React.useState(null);

    const decoder = new InputDataDecoder(abi);

    const validate = (values) => {
        const errors = required(['txid', 'wallet'], values);
        if (!errors.txid) {
            const txidError = txid(values.txid);
            if (txidError) {
                errors.txid = txid(values.txid);
            }
            const walletError = wallet(values.wallet);
            if (walletError) {
                errors.wallet = wallet(values.wallet);
            }
        }
        return errors;
    };

    const handleSubmit = async values => {
        transaction(values.txid).then(res => {
            console.log(res)
            const to = `0x${decoder.decodeData(res.input).inputs[0]}`;
            if (to.toLowerCase() === config.wallet.toLowerCase()) {
                const value = decoder.decodeData(res.input).inputs[1].words[0];
                setTxidValue(values.txid);
                setWalletValue(values.wallet);
                setToValue(to);
                setFromValue(res.from);
                setAmount(value / 1000000);
                setMessage(`Your transaction amount is ${value / 1000000},` + `are you sure to apply?`);
            } else {
                setTxidValue(null);
                setWalletValue(null);
                setToValue(null);
                setDisApply(true);
                setAmount(0);
                setMessage('Cant find this transaction');
            }
        });
        setOpen(true);
    };

    const handleApply = (values) => {
        console.log(values);
        if (txidValue !== null && walletValue !== null) {
            transactions({
                txid: txidValue,
                from: fromValue,
                to: toValue,
                wallet: walletValue,
                token: token,
                amount: amount,
                timeStamp: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
            })
                .then(
                    (res) => {
                        console.log(res.data)
                        setMessage(`Successfully applied the transaction! Check it from your email or Transactions menu`);
                        setDisApply(true);
                        setReload(true);
                    }
                )
                .catch(err => {
                    const msg = messages[locale].Errs[err.response.data.errors];
                    setMessage(msg);
                    console.log(err.response.data)
                });
        }
    }

    return (
        <Box className={classes.container}>
            <AlertDialog
                open={open}
                setOpen={setOpen}
                message={message}
                handleApply={() => handleApply()}
                disApply={disApply}
                setDisApply={setDisApply}
                reload={reload}
            />
            <Box className={classes.box}>
                <Typography className={classes.welcome}>
                    {messages[locale].User.Welcome}
                </Typography>
                <Typography className={classes.welcometext}>
                    STEP1
            </Typography>
                <Typography className={`${classes.welcometext} ${classes.spacegap}`}>
                    Send USTD to the specified address and get TXID
            </Typography>
                <Typography className={classes.welcometext}>
                    ・Receive address
                </Typography>
                <Typography
                    className={`${classes.welcometext} ${classes.spacegap}`}
                    style={{ wordWrap: "break-all" }}
                >
                    0x8B85C4F7764C2Dcb99a2AF0efdB26f
                    301B03f18a
                </Typography>
                <Typography className={classes.welcometext}>
                    ・Allotment Amount
                </Typography>
                <Typography className={`${classes.welcometext} ${classes.spacegap}`}>
                    300 USDT～ = 30000TAVITT-R～
                    {/* {messages[locale].User.WelcomeText} */}
                </Typography>
                <Typography className={classes.welcometext}>
                    STEP 2
                </Typography>
                <Typography className={`${classes.welcometext} ${classes.spacegap}`}>
                    Please submit your TXID and your wallet address
            </Typography>
                <Typography className={classes.welcometext}>
                    STEP 3
                </Typography>
                <Typography className={classes.welcometext}>
                    Tavittcoin-R will be delivered to your registered wallet
            </Typography>
            </Box>
            <Form
                onSubmit={handleSubmit}
                // subscription={{ submitting: true }}
                validate={validate}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className={classes.form}
                        noValidate
                    >
                        <Field
                            // autoComplete="txid"
                            // disabled={formDis}
                            autoFocus
                            component={RFTextField}
                            disabled={submitting || sent}
                            fullWidth
                            label={messages[locale].Txid}
                            margin="normal"
                            name="txid"
                            required
                            size="small"
                            placeholder='Txid'
                        />
                        <Field
                            // disabled={formDis}
                            fullWidth
                            size="small"
                            component={RFTextField}
                            disabled={submitting || sent}
                            required
                            name="wallet"
                            // autoComplete="current-password"
                            label={messages[locale].Wallet}
                            margin="normal"
                            placeholder='Wallet'
                        />
                        <FormSpy subscription={{ submitError: true }}>
                            {({ submitError }) =>
                                submitError ?
                                    <FormFeedback className={classes.feedback} error>
                                        {submitError}
                                    </FormFeedback>
                                    : null
                            }
                        </FormSpy>
                        <motion.div
                            animate={{
                                x: 0,
                                y: 0,
                                scale: 0.9,
                                rotate: 0,
                            }}
                            whileHover={{ scale: 1 }}
                        >
                            <FormButton
                                // disabled={formDis}
                                className={classes.button}
                                disabled={submitting || sent}
                                size="small"
                                color="secondary"
                                fullWidth
                            >
                                {submitting || sent ? `${messages[locale].Submitting} ` : `${messages[locale].Submit} `}
                            </FormButton>
                        </motion.div>
                        <Box className={classes.down}>
                            <img
                                className={classes.image}
                                src={require("static/icons/tavittlogo-round.png")}
                                alt="Tavittlogo"
                            />
                            <Box>
                                <Typography className={classes.email}>
                                    {messages[locale].Email}
                                </Typography>
                                <Typography className={classes.address}>
                                    info@tavitt.co.jp
                                </Typography>
                            </Box>
                        </Box>
                    </form>
                )}
            />
        </Box>
    )
}
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { applyMiddleware, createStore, compose } from 'redux';

import loggerMiddleware from 'redux/middleware/logger';
import monitorReducerEnhancer from 'redux/enhancers/monitorReducer';

import rootReducer from 'redux/reducers/reducers';
import thunkMiddleware from 'redux-thunk';

import localStorage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage: localStorage,
    whitelist: ['user', 'locale'],
    timeout: null,
}

const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware);
const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, undefined, composedEnhancers);
export const persistor = persistStore(store);
// --- Post bootstrap -----
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import Typography from 'modules/components/Typography';
import AppForm from 'modules/views/AppForm';
import { email, comfirm, required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import AppFooter from 'modules/views/AppFooter';
import { motion } from 'framer-motion';

import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';
import { FORM_ERROR } from "final-form";
// import { useHistory } from 'react-router-dom';

import { signup } from "apis/Auth";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    feedback: {
        marginTop: theme.spacing(2),
    },
}));

function SignUp() {

    const locale = useSelector(state => state.locale);
    // const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    // const history = useHistory();

    const classes = useStyles();
    const [sent, setSent] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState(null);

    const validate = (values) => {
        const errors = required(['comfirm', 'email', 'password'], values);

        if (!errors.email) {
            const emailError = email(values.email, values);
            if (emailError) {
                errors.email = email(values.email, values);
            }
        }
        if (!errors.comfirm) {
            const comfirmError = comfirm(values.password, values.comfirm);
            if (comfirmError) {
                errors.comfirm = comfirm(values.password, values.comfirm);
            }
        }

        return errors;
    };

    const handleSubmit = (values) => {
        console.log(values.email);
        // setSent(true);
        return signup({
            email: values.email,
            password: values.password,
            locale: locale
        })
            .then(res => {
                // console.log(res.data.code);
                setSent(true);
                setSuccessMsg('Seccessfully sent the verification email.');
            })
            .catch(err => {
                // console.log(err.response);
                setSent(false);
                const msg = messages[locale].Errs[err.response.data.errors];
                // console.log(msg);
                // if (err.response.data.errors) return {
                return { [FORM_ERROR]: `${msg}` }
            })
    };

    return (
        <React.Fragment>
            <AppForm>
                <React.Fragment>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        {messages[locale].Signup}
                    </Typography>
                    <Typography variant="body2" align="center">
                        <Link style={{ color: 'gray' }} to="/signinview" underline="always">
                            {messages[locale].Already}
                        </Link>
                    </Typography>
                </React.Fragment>
                <Form
                    onSubmit={handleSubmit}
                    subscription={{ submitting: true }}
                    validate={validate}
                    render={({ handleSubmit, submitting }) =>
                        <form
                            onSubmit={handleSubmit}
                            className={classes.form}
                            noValidate
                        >
                            <Grid container spacing={2}>
                            </Grid>
                            <Field
                                autoComplete="email"
                                component={RFTextField}
                                disabled={submitting || sent}
                                fullWidth
                                label={messages[locale].Email}
                                margin="normal"
                                name="email"
                                required
                                size="large"
                            />
                            <Field
                                fullWidth
                                component={RFTextField}
                                disabled={submitting || sent}
                                required
                                name="password"
                                autoComplete="current-password"
                                label={messages[locale].Password}
                                type="password"
                                margin="normal"
                                size="large"
                            />
                            <Field
                                fullWidth
                                component={RFTextField}
                                disabled={submitting || sent}
                                required
                                name="comfirm"
                                autoComplete="current-password"
                                label={messages[locale].Comfirm}
                                type="password"
                                margin="normal"
                                size="large"
                            />
                            <FormSpy subscription={{ submitError: true }}>
                                {({ submitError }) =>
                                    submitError ? (
                                        <FormFeedback className={classes.feedback} error>
                                            {submitError}
                                        </FormFeedback>
                                    ) : null
                                }
                            </FormSpy>
                            {
                                successMsg ?
                                    <FormFeedback
                                        className={classes.feedback}
                                        success
                                    >
                                        {successMsg}
                                        {/* {messages[locale].Suc[successMsg]} */}
                                    </FormFeedback> : null
                            }
                            {/* <FormSpy subscription={{ submitSucceeded: true }}>
                                {({ submitSucceeded }) =>
                                    submitSucceeded ? (
                                        <FormFeedback className={classes.feedback} error>
                                            {submitSucceeded}
                                        </FormFeedback>
                                    ) : null
                                }
                            </FormSpy> */}

                            <motion.div
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 0.9,
                                    rotate: 0,
                                }}
                                whileHover={{ scale: 1 }}
                            >
                                <FormButton
                                    className={classes.button}
                                    disabled={submitting || sent}
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                >
                                    {submitting ? `${messages[locale].InProgressSignup}` : sent ? `${messages[locale].Sent}` : `${messages[locale].Signup}`}
                                </FormButton>
                            </motion.div>
                        </form>
                    }
                />
            </AppForm>
            <AppFooter />
        </React.Fragment>
    );
}

export default SignUp;
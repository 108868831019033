// --- Post bootstrap -----
import React from 'react';
import Paper from 'modules/components/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ResetPw from 'modules/views/ResetPw';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingBottom: 50
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            width: "95%",
            marginTop: 20,
        },
        display: 'flex',
        flexDirection: 'row',
        // maxWidth: 1000,
        width: "80%",
        maxHeight: '100vh',
        marginTop: 50,
        marginBottom: 50,
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 5,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
    }
}));

function ResetPassword() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Paper className={classes.paper} elevation={3}>
                    <ResetPw />
                </Paper>
            </Container>
        </React.Fragment >
    )
}

export default ResetPassword;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
// import Cookies from 'js-cookie';
// import Auth from './AuthInfo';
// const isAuthenticated = useSelector(state => state.isAuthenticated.isAuthenticated);

const ProtectedRouteWithLayout = props => {
    const { layout: Layout, component: Component, isAuthenticated, ...rest } = props;
    // const isAuthenticated = useSelector(state => state.isAuthenticated.isAuthenticated);
    // console.log(Cookies.get('jwt'));

    return (
        <Route
            {...rest}
            render={matchProps => isAuthenticated ?
                <Layout>
                    <Component {...matchProps} />
                </Layout>
                :
                <Redirect to={{ pathname: "/signinview" }} />
            }
        />
    );
}

ProtectedRouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default ProtectedRouteWithLayout;
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from 'modules/components/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        // flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        // overflow: 'hidden',
        // [theme.breakpoints.down('sm')]: {
        //     padding: theme.spacing(5),
        // },
        // padding: theme.spacing(10)
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5),
        },
        padding: theme.spacing(10)
    },
    title: {
        // display: 'flex',
        // justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 28
        },
        fontSize: 32
    },
    card1: {
        width: '90%',
        display: 'flex',
        boxShadow: 'none',
        padding: theme.spacing(3)
    },
    card2: {
        width: '90%',
        display: 'flex',
        boxShadow: '0 0 5px 1px #ccc',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
        },
    },
    subtitle1: {
        color: theme.palette.secondary.main,
        borderBottom: `2px solid ${theme.palette.secondary.light}`,
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    subcontent1: {
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    items: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3),
        },
    },
    coloredtext: {
        color: theme.palette.secondary.main,
    },
    subtitle2: {
        fontSize: 12,
        paddingBottom: theme.spacing(1),
    },
    subcontent2: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
    },
    addline: {
        borderLeft: `3px solid ${theme.palette.common.black}`,
    },
    items2: {
        paddingLeft: theme.spacing(2),
    },
    image: {
        width: '60%',
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

function SaleInfo() {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="subtitle2" className={classes.title}>
                    Tavittcoin-R (TAVITT-R)
                </Typography>
                <Card className={classes.card1}>
                    <Grid container >
                        <Grid item xs={4} className={classes.items2}>
                            <Typography className={classes.subtitle2}>
                                Start Time
                        </Typography>
                            <Typography className={classes.subcontent2}>
                                Comming soon{/* 2020/06/17 13:00:00 */}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}
                            className={`${classes.items2} ${classes.addline}`}
                        >
                            <Typography className={classes.subtitle2}>
                                Total supply
                        </Typography>
                            <Typography className={classes.subcontent2}>
                                5Billion TAVITT-R
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.items2}>
                            <Typography className={classes.subtitle2}>
                                Token type{/* Total Subscription Allotement */}
                            </Typography>
                            <Typography className={classes.subcontent2}>
                                OKChain / ERC-20
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={classes.card2}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} className={classes.items}>
                            <Typography className={classes.subtitle1}>
                                Pre-Sale Session Supply
                        </Typography>
                            <Typography className={classes.subcontent1}>
                                300,000,000 TAVITT-R
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={6} md={3} className={classes.items}>
                            <Typography className={classes.subtitle1}>
                                {'OKEX IEO Session Supply'}
                            </Typography>
                            <Typography className={classes.subcontent1}>
                                {'100,000,000'}
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} className={classes.items}>
                            <Typography className={classes.subtitle1}>
                                {'Sale Price'}
                            </Typography>
                            <Typography className={classes.subcontent1}>
                                $0.01
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={classes.items}>
                            <Typography className={classes.subtitle1}>
                                {'Allotment Amount'}
                            </Typography>
                            <Typography className={classes.subcontent1}>
                                300 USDT~=30000 TAVITT-R~
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <img
                    className={classes.image}
                    src={require("static/pictures/asign.png")}
                    alt="round"
                />
            </Container>
        </section>
    );
}

// Wgrt.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default SaleInfo;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import AppBar from 'modules/components/AppBar';
import Toolbar, { styles as toolbarStyles } from 'modules/components/Toolbar';
import Typography from 'modules/components/Typography';
import TextField from 'modules/components/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from 'translations/messages';
import { changeLocale } from 'redux/reducers/actions';
import { messages } from 'translations/messages';
import Box from '@material-ui/core/Box';
// import Auth from 'modules/AuthInfo';

import Button from '@material-ui/core/Button';
import { logout } from 'apis/Auth';
import { isAuth, tokenUpdate } from "redux/reducers/actions";
// import Cookies from 'js-cookie';
// import CheckCookies from 'modules/AuthInfo';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import CButton from 'modules/components/Button';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
// import { logout } from 'redux/reducers/actions';
// import logoutRedux
const jwt = require('jsonwebtoken');

const useStyles = makeStyles(
    (theme) => ({
        toolbar: {
            boxShadow: '0 0 1px 1px #ccc',
            justifyContent: 'space-between'
        },
        //login signup and language
        right: {
            width: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                width: '90%',
            },
        },
        linkBox: {
            display: 'flex',
        },
        linkText: {
            marginRight: theme.spacing(1),
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        linkMenu: {
            display: 'none',
            marginRight: theme.spacing(1),
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                marginRight: 0,
            },
        },
        linkColor: {
            color: theme.palette.secondary.main,
        },
        account: {
            color: theme.palette.common.black,
            paddingRight: theme.spacing(3),
        },
        //logo
        image: {
            height: 35,
        },
        //language
        selectstyle: {
            width: '30%',
            maxWidth: 100,
            [theme.breakpoints.down('xs')]: {
                maxWidth: 70,
                // display: 'none',
            },
        },
        cbutton: {
            color: theme.palette.common.white,
            minWidth: 200,
            height: 20,
            marginLeft: 10,
            [theme.breakpoints.down('sm')]: {
                minWidth: 60,
                // marginLeft: 5,
                paddingLeft: 0,
                paddingRight: 0
            },
        },
        swaptext: {
            fontSize: 12,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        menuButton: {
            // width: 1,
            // backgroundColor: 'pink',
            // padding: 0,
            // margin: 0
        }
    })
);

function AppAppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const token = useSelector(state => state.user.token);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        if (token) {
            const exp = jwt.decode(token).exp;
            Date.now() >= exp * 1000 ? dispatch(isAuth(false)) : dispatch(isAuth(true))
        } else {
            dispatch(isAuth(false))
        }
    }, [])

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar variant="dense" className={classes.toolbar}>
                    <Button
                        onClick={() => {
                            history.push('/');
                            window.scrollTo(0, 0);
                        }}
                    >
                        <img
                            className={classes.image}
                            src={require("static/icons/tavittlogo.png")}
                            alt="Tavittlogo"
                        />
                    </Button>
                    <div className={classes.right}>
                        {!isAuthenticated ?
                            <Box className={classes.linkBox}>
                                <Button
                                    className={classes.linkText}
                                    onClick={() => history.push('/signinview')}
                                >
                                    {messages[locale].Login}
                                </Button>
                                <Button
                                    className={`${classes.linkText} ${classes.linkColor}`}
                                    onClick={() => history.push('/signupview')}
                                >
                                    {messages[locale].Signup}
                                </Button>
                                <div className={classes.linkMenu}>
                                    <Button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        // startIcon={<MenuOpenIcon />}
                                        className={classes.menuButton}
                                    >
                                        <MenuOpenIcon
                                        // className={classes.menuButton}
                                        /></Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => { history.push('/signinview'); handleClose(); }}>{messages[locale].Login}</MenuItem>
                                        <MenuItem onClick={() => { history.push('/signupview'); handleClose(); }} > {messages[locale].Signup}</MenuItem>
                                    </Menu>
                                </div>
                            </Box>
                            :
                            <IconButton
                                className={classes.rightLink}
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => history.push('/home')}
                            >
                                <AccountCircle />
                            </IconButton>
                        }
                        <TextField
                            select
                            SelectProps={{
                                native: true,
                            }}
                            className={classes.selectstyle}
                            onChange={(lang) => dispatch(changeLocale(lang.target.value))}
                            value={locale}
                            size="small"
                        >
                            {LANGUAGES.map((language) => (
                                <option value={language.code} key={language.code}>
                                    {window.innerWidth < 600 ? language.short : language.name}
                                </option>
                            ))}
                        </TextField>
                        <CButton
                            className={classes.cbutton}
                            onClick={() => history.push('/swap')}
                        >
                            {window.innerWidth > 600 ?
                                <Typography className={classes.swaptext}>
                                    Swap App
                            </Typography> : null}
                            <SwapHorizIcon />
                        </CButton>
                    </div>
                </Toolbar>
            </AppBar>
        </div >
    );
}

// AppAppBar.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default AppAppBar
// --- Post bootstrap -----
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'modules/components/Typography';
import AppFooter from 'modules/views/AppFooter';
import AppForm from 'modules/views/AppForm';
import { email, required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import { motion } from 'framer-motion';

import { messages } from 'translations/messages';
import { useSelector, useDispatch } from 'react-redux';
// import { storeToken } from 'redux/reducers/actions';

import { FORM_ERROR } from "final-form";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { login } from "apis/Auth";
import { isAuth, tokenUpdate, emailUpdate } from "redux/reducers/actions";
// import Cookies from 'js-cookie';
// import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    feedback: {
        marginTop: theme.spacing(2),
    },
}));

function SignIn() {
    const locale = useSelector(state => state.locale);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [sent, setSent] = React.useState(false);

    // const [dis, setDis] = React.useState(false);
    // const [successMsg, setSuccessMsg] = React.useState(null);
    // const [errorMsg, setErrorMsg] = React.useState(null);

    const validate = (values) => {
        const errors = required(['email', 'password'], values);

        if (!errors.email) {
            const emailError = email(values.email, values);
            if (emailError) {
                errors.email = email(values.email, values);
            }
        }

        return errors;
    };

    const handleSubmit = async values => {
        return login({
            email: values.email,
            password: values.password
        })
            .then(res => {
                // console.log(res.data);
                dispatch(tokenUpdate(res.data))
                dispatch(emailUpdate(values.email))
                dispatch(isAuth(true));
                history.push('/home');
            })
            .catch(err => {
                // console.log(err.response.data);
                setSent(false);
                // var msg = '';
                // if (err.message) { msg = JSON.stringify(err.message); }
                // else { msg = messages[locale].Errs[err.response.data.errors]; }
                const msg = messages[locale].Errs[err.response.data.errors];
                return { [FORM_ERROR]: `${msg}` }
            })
    };

    return (
        <React.Fragment>
            <AppForm>
                <React.Fragment>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        {messages[locale].Login}
                    </Typography>
                    <Typography variant="body2" align="center">
                        {messages[locale].NotMember}
                        <Link style={{ color: 'gray' }} to="/signupview">
                            {messages[locale].ClickHere}
                        </Link>
                    </Typography>
                </React.Fragment>
                <Form
                    onSubmit={handleSubmit}
                    subscription={{ submitting: true }}
                    validate={validate}
                    render={({ handleSubmit, submitting }) => (
                        <form
                            onSubmit={handleSubmit}
                            className={classes.form}
                            noValidate
                        >
                            <Field
                                autoComplete="email"
                                autoFocus
                                component={RFTextField}
                                disabled={submitting || sent}
                                fullWidth
                                label={messages[locale].Email}
                                margin="normal"
                                name="email"
                                required
                                size="large"
                            />
                            <Field
                                fullWidth
                                size="large"
                                component={RFTextField}
                                disabled={submitting || sent}
                                required
                                name="password"
                                autoComplete="current-password"
                                label={messages[locale].Password}
                                type="password"
                                margin="normal"
                            />
                            <FormSpy subscription={{ submitError: true }}>
                                {({ submitError }) =>
                                    submitError ?
                                        <FormFeedback className={classes.feedback} error>
                                            {submitError}
                                        </FormFeedback>
                                        : null
                                }
                            </FormSpy>
                            <motion.div
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 0.9,
                                    rotate: 0,
                                }}
                                whileHover={{ scale: 1 }}
                            >
                                <FormButton
                                    className={classes.button}
                                    disabled={submitting || sent}
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                >
                                    {submitting || sent ? `${messages[locale].InProgressSignin} ` : `${messages[locale].Login} `}
                                </FormButton>
                            </motion.div>
                        </form>
                    )}
                />
                <motion.div whileHover={{ scale: 1.1 }} >
                    <Typography align="center">
                        <Link style={{ color: 'gray' }} to="/forgotpassword" >
                            {messages[locale].FogetPW}
                        </Link>
                    </Typography>
                </motion.div>
            </AppForm>
            <AppFooter />
        </React.Fragment >
    );
}

export default SignIn;
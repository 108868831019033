import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './Routes';
import theme from './theme';

import { IntlProvider } from 'react-intl';
import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';

const browserHistory = createBrowserHistory();

export default function App() {
    const locale = useSelector(state => state.locale);

    return <IntlProvider locale="en" messages={messages[locale]}>
        <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
                <Routes />
            </Router>
        </ThemeProvider>
    </IntlProvider>
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from 'modules/components/Typography';
import TextField from 'modules/components/TextField';
import { changeLocale } from 'redux/reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from 'translations/messages';
import EmailIcon from '@material-ui/icons/Email';
import MButton from '@material-ui/core/Button';
import { messages } from 'translations/messages';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Copyright() {
    return (
        <React.Fragment>
            {'© '}
            <Link color="inherit" href="https://tavitt.co.jp/">
                Tavitt
            </Link>{' '}
            {new Date().getFullYear()}
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        boxShadow: '0 0 1px 1px #ccc',
        // position: 'relative',
        backgroundColor: theme.palette.primary.main,
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex',
    },
    iconsWrapper: {
        height: 120,
    },
    icons: {
        display: 'flex',
    },
    icon: {
        width: 20,
        // height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        // borderRadius: '7px',
        marginRight: theme.spacing(1),
        '&:hover': {
            // backgroundColor: 'gray',
            // borderRadius: '7px'
        },
    },
    list: {
        margin: 0,
        listStyle: 'none',
        padding: 0,
    },
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    language: {
        marginTop: theme.spacing(1),
        width: 300,
    },
    image: {
        height: 63,
        [theme.breakpoints.down('xs')]: {
            height: 43,
        },
        // marginTop: theme.spacing(2),
    },
    text: {
        color: '#707070'
    },
    mbutton: {
        width: 250,
        height: 50,
        justifyContent: "flex-start",
        paddingLeft: theme.spacing(2.5),
        autoCapitalize: false,
        textTransform: 'none'
    },
    selectstyle: {
        width: 250,
        height: 50,
        color: "#0066B4",
        alignItems: "center",
        // backgroundColor: "#0066B4",
        borderRadius: "5px"
    },
    address: {

    },
    copyright: {
        paddingBottom: theme.spacing(1)
    }
}));

export default function AppFooter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale);
    const [open, setOpen] = React.useState(false);
    const [copy, setCopy] = React.useState('info@tavitt.co.jp');
    // const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    // if (isAuthenticated) return null
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Typography component="footer" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={8} md={4}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item className={classes.icons}>
                                <img
                                    className={classes.image}
                                    src={require("static/icons/tavittlogo.png")}
                                    alt="Tavittlogo"
                                />
                            </Grid>
                            <Grid item className={classes.text}>
                                <Typography className={classes.address}>
                                    Skulthai Surawong Tower, 41th FL 141 Surawong Road, Suriyawong, Bangrak, Bangkok 10500 Thailand
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Typography className={classes.text}>
                                    {messages[locale].Contact}
                                </Typography>
                                <CopyToClipboard
                                    text={copy}
                                    onCopy={() => setOpen(true)}
                                >
                                    <MButton
                                        color="secondary"
                                        variant="contained"
                                        className={classes.mbutton}
                                        startIcon={<EmailIcon />}
                                        onClick={() => setCopy('info@tavitt.co.jp')}
                                    >
                                        info@tavitt.co.jp
                                    </MButton>
                                </CopyToClipboard>
                            </li>
                            <li className={classes.listItem}>
                                <Typography className={classes.text}>
                                    {messages[locale].Language}
                                </Typography>
                                <TextField
                                    select
                                    // disabled
                                    SelectProps={{
                                        native: true,
                                    }}
                                    className={classes.selectstyle}
                                    onChange={(lang) => dispatch(changeLocale(lang.target.value))}
                                    value={locale}
                                >
                                    {LANGUAGES.map((language) => (
                                        <option value={language.code} key={language.code}>
                                            {language.name}
                                        </option>
                                    ))}
                                </TextField>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <ul className={classes.list}>
                            <li>
                                <MButton
                                    className={classes.text}
                                    href='https://www.facebook.com/TavittThailand/'
                                >
                                    <img src={require("static/icons/facebook.png")} alt="Facebook" className={classes.icon} />
                                    Facebook
                                </MButton>
                            </li>
                            <li>
                                <MButton className={classes.text}
                                    href='https://twitter.com/tavitt_coltd'
                                >
                                    <img src={require("static/icons/twitter.png")} alt="Twitter" className={classes.icon} />
                                    Twitter
                                </MButton>
                            </li>
                            <li>
                                <MButton className={classes.text}
                                    href='https://github.com/Tavitt/token'>
                                    <img src={require("static/icons/github.png")} alt="Github" className={classes.icon} />
                                    Github
                                </MButton>
                            </li>
                            <li>
                                <MButton className={classes.text}
                                    href='https://t.me/tavitt_official'>
                                    <img src={require("static/icons/paperplane.png")} alt="Github" className={classes.icon} />
                                    Telegram
                                </MButton>
                            </li>
                            <li>
                                <MButton className={classes.text}
                                    href='https://medium.com/tavitt'>
                                    <img src={require("static/icons/medium.png")} alt="Github" className={classes.icon} />
                                    Medium
                                </MButton>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Snackbar
                    autoHideDuration={3000}
                    open={open}
                    onClose={handleClose}
                // message="Wallet address coppied"
                >
                    <MuiAlert
                        onClose={handleClose}
                        severity="success"
                        elevation={6}
                        variant="filled"
                    >
                        email address coppied
                    </MuiAlert>
                </Snackbar>
            </Container>
            <Typography className={classes.copyright}>
                {`© Tavitt `}
                {new Date().getFullYear()}
            </Typography>
        </Typography >
    );
}
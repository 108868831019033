import {
    UPLOAD_ADDRESS,
    CHANGE_LOCALE,
    IS_AUTHENTICATED,
    UPLOAD_TXID,
    UPDATE_TOKEN,
    UPDATE_USER_EMAIL,
    USER_LOGOUT
} from './actions';

import { combineReducers } from 'redux';

function user(state = {
    isAuthenticated: false,
    // address: null,
    // txid: null,
    token: null,
    // amount: null
}, action) {
    switch (action.type) {
        case IS_AUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.payload
            })
        // case UPLOAD_ADDRESS:
        //     return Object.assign({}, state, {
        //         address: action.payload
        //     })
        // case UPLOAD_TXID:
        //     return Object.assign({}, state, {
        //         txid: action.payload
        //     })
        case UPDATE_TOKEN:
            return Object.assign({}, state, {
                token: action.payload
            })
        case UPDATE_USER_EMAIL:
            return Object.assign({}, state, {
                email: action.payload
            })
        // case USER_LOGOUT:
        //     return Object.assign({}, state, {
        //         isAuthenticated: false,
        //         token: null
        //     })
        default:
            return state
    }
}

function locale(state = 'en-US', action) {
    if (action.type === CHANGE_LOCALE) state = action.payload
    return state
}

const rootReducer = combineReducers({
    user,
    locale
})

export default rootReducer
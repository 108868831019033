// --- Post bootstrap -----
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../modules/components/Typography';
import AppFooter from '../modules/views/AppFooter';
// import AppAppBar from '../modules/views/AppAppBar';
import AppForm from '../modules/views/AppForm';
import { email, required } from '../modules/form/validation';
import RFTextField from '../modules/form/RFTextField';
import FormButton from '../modules/form/FormButton';
import FormFeedback from '../modules/form/FormFeedback';

import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { resetpw } from "apis/Auth";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    feedback: {
        marginTop: theme.spacing(2),
    },
}));

function ForgotPassword() {
    const locale = useSelector(state => state.locale);
    // const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const classes = useStyles();
    // const history = useHistory();
    const [sent, setSent] = React.useState(false);
    const [sending, setSending] = React.useState(false);

    const [successMsg, setSuccessMsg] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const validate = (values) => {
        const errors = required(['email'], values);

        if (!errors.email) {
            const emailError = email(values.email, values);
            if (emailError) {
                errors.email = email(values.email, values);
            }
        }

        return errors;
    };

    const handleSubmit = (values) => {
        setSending(true);
        // console.log(sent)
        // setSent(true);
        resetpw({
            email: values.email,
            locale: locale
        })
            .then(res => {
                // console.log(sent)
                setSending(false);
                setSent(true);
                setSuccessMsg(res.data);
                // setDis(true);
            })
            .catch(err => {
                setSending(false);
                setSent(false);
                setErrorMsg(err.response.data.errors);
            })
    };

    return (
        <React.Fragment>
            <AppForm>
                <React.Fragment>
                    <Typography variant="h3" gutterBottom marked="center" align="center">
                        {messages[locale].FogetPW1}
                    </Typography>
                    <Typography variant="body2" align="center">
                        {messages[locale].FogetPW2} {messages[locale].FogetPW3}
                    </Typography>
                </React.Fragment>
                <Form
                    onSubmit={handleSubmit}
                    subscription={{ submitting: true }}
                    validate={validate}
                    render={({ handleSubmit, submitting }) =>
                        <form onSubmit={handleSubmit} className={classes.form} noValidate>
                            <Field
                                autoFocus
                                autoComplete="email"
                                component={RFTextField}
                                disabled={submitting || sent}
                                fullWidth
                                label={messages[locale].Email}
                                margin="normal"
                                name="email"
                                required
                                size="large"
                            />
                            {
                                successMsg ?
                                    <FormFeedback
                                        className={classes.feedback}
                                        success
                                    >
                                        {messages[locale].Suc[successMsg]}
                                    </FormFeedback> : null
                            }
                            {
                                errorMsg ?
                                    <FormFeedback
                                        className={classes.feedback}
                                        error
                                    >
                                        {messages[locale].Errs[errorMsg]}
                                    </FormFeedback> : null
                            }
                            <motion.div
                                animate={{
                                    x: 0,
                                    y: 0,
                                    scale: 0.9,
                                    rotate: 0,
                                }}
                                whileHover={{ scale: 1 }}
                            >
                                <FormButton
                                    className={classes.button}
                                    disabled={sending || sent}
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                >
                                    {sending ? `${messages[locale].InProgressResetPW}` : sent ? `${messages[locale].Sent}` : `${messages[locale].SendResetLink}`}
                                </FormButton>
                            </motion.div>
                        </form>}
                />
            </AppForm>
            <AppFooter />
        </React.Fragment>
    );
}

export default ForgotPassword;
import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
    palette: {
        primary: {
            // light: '#69696a',
            main: '#ffffff',
            // dark: '#1e1e1f',
        },
        secondary: {
            light: '#ECF5FC',
            main: '#0380E0',
            dark: '#0066B4',
        },
        text: {
            light: '#0793FF',
            main: '#000000',
            dark: '#0066B4',
        },
        background: {
            light: '#fff5f8',
            main: '#ff3366',
            dark: '#232728',
        },
        warning: {
            main: '#ffc071',
            dark: '#ffb25e',
        },
        error: {
            xLight: red[50],
            main: red[500],
            dark: red[700],
        },
        success: {
            xLight: green[50],
            main: green[500],
            dark: green[700],
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        },
        // fontFamily: "'Work Sans', sans-serif",
        fontFamily: "'Verdana',sans-serif",
        fontSize: 14,
        fontWeightLight: 300, // Work Sans
        fontWeightRegular: 400, // Work Sans
        fontWeightMedium: 700, // Roboto Condensed
        fontFamilySecondary: "'Verdana', sans-serif",
    },
});

const fontHeader = {
    color: rawTheme.palette.text.main,
    fontWeight: rawTheme.typography.fontWeightMedium,
    fontFamily: rawTheme.typography.fontFamilySecondary,
    // textTransform: 'uppercase',
};

const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            default: rawTheme.palette.common.white,
            placeholder: grey[200],
        },
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            ...rawTheme.typography.h1,
            ...fontHeader,
            letterSpacing: 0,
            fontSize: 60,
        },
        h2: {
            ...rawTheme.typography.h2,
            ...fontHeader,
            fontSize: 48,
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 42,
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            fontSize: 36,
        },
        h5: {
            ...rawTheme.typography.h5,
            color: rawTheme.palette.primary.main,
            fontSize: 20,
            fontWeight: rawTheme.typography.fontWeightLight,
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            color: rawTheme.palette.primary.main,
            fontSize: 18,
        },
        extratext: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            color: rawTheme.palette.secondary.main,
            fontSize: 18,
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 18,
        },
        subtitle2: {
            ...rawTheme.typography.h6,
            color: rawTheme.palette.secondary.main,
            fontSize: 23,
        },
        body1: {
            ...rawTheme.typography.body2,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 16,
        },
        body2: {
            ...rawTheme.typography.body1,
            fontSize: 14,
        },
        body3: {
            ...rawTheme.typography.body1,
            fontSize: 12,
        },
    },
};

export default theme;

// --- Post bootstrap -----
import React from 'react';
import AppFooter from 'modules/views/AppFooter';
import ProductHero from 'modules/views/ProductHero';
import HowTo from 'modules/views/HowTo';
import RoadMap from 'modules/views/RoadMap';
import WalletInfo from 'modules/views/WalletInfo';
import WhatIsTavitt from 'modules/views/WhatIsTavitt';
import SaleInfo from 'modules/views/SaleInfo';

function Index() {
    return (
        <React.Fragment>
            <ProductHero />
            <WalletInfo />
            <HowTo />
            <SaleInfo />
            <WhatIsTavitt />
            <RoadMap />
            {/* <ProductCategories /> */}
            {/* <ProductHowItWorks /> */}
            {/* <ProductSmokingHero /> */}
            <AppFooter />
        </React.Fragment>
    );
}

export default Index;
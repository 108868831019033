// import React from 'react'
import isEmail from 'validator/lib/isEmail';
import Web3 from 'web3';
// import { FormattedMessage } from 'react-intl'

export function email(value) {
    return value && !isEmail(value.trim()) ? 'Invalid email' : null;
}
export function comfirm(value1, value2) {
    return value1 !== value2 ? 'Passwords not match' : null;
}
export function txid(value) {
    return value && !Web3.utils.isHex(value.trim()) ? 'Invalid hash text' : null;
}
export function wallet(value) {
    return value && !Web3.utils.isAddress(value.trim()) ? 'Invalid wallet address' : null;
}

function isDirty(value) {
    return value || value === 0;
}

export function required(requiredFields, values) {
    return requiredFields.reduce(
        (fields, field) => ({
            ...fields,
            ...(isDirty(values[field]) ? undefined : { [field]: 'Required' }),
        }),
        {},
    );
}

import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from 'modules/components/Typography';
import Button from 'modules/components/Button';
import { motion } from 'framer-motion';
import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.dark,
        // backgroundColor: theme.palette.common.white,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5),
        },
        padding: theme.spacing(10),
    },
    //grid sub container
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(5),
    },
    //main title
    item1: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            fontSize: 28
        },
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        fontSize: 32
    },
    //main text
    item2: {
        fontSize: 18,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    //icons
    image: {
        height: 60,
    },
    //step title
    title: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            fontSize: '18px',
        },
        color: theme.palette.common.white,
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    steptext: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            fontSize: 14
        },
        color: theme.palette.common.white,
        fontSize: 16,
        marginTop: theme.spacing(5),
    },
    button: {
        width: '50%',
        maxWidth: 500,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(5)
        }
    }
}));

//animation
// const container = {
//     hidden: { opacity: 0 },
//     visible: {
//         opacity: 1,
//         // scale: 1,
//         transition: {
//             when: "beforeChildren",
//             staggerChildren: 0.1,
//             opacity: { duration: 1.5 }
//         }
//     }
// }

function HowTo() {
    const classes = useStyles();
    const locale = useSelector(state => state.locale);
    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="subtitle2" className={classes.item1}>
                    {messages[locale].HowTo.title}
                </Typography>
                <Typography className={classes.item2}>
                    {messages[locale].HowTo.text1}
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4} className={classes.item}>
                        <Typography className={classes.title}>
                            {messages[locale].HowTo.step1}
                        </Typography>
                        <img
                            className={classes.image}
                            src={require("static/icons/step1.png")}
                            alt="Step1"
                        />
                        <Typography className={classes.steptext}>
                            {messages[locale].HowTo.step1text}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.item}>
                        <Typography className={classes.title}>
                            {messages[locale].HowTo.step2}
                        </Typography>
                        <img
                            className={classes.image}
                            src={require("static/icons/step2.png")}
                            alt="Step2"
                        />
                        <Typography className={classes.steptext}>
                            {messages[locale].HowTo.step2text}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.item}>
                        <Typography className={classes.title}>
                            {messages[locale].HowTo.step3}
                        </Typography>
                        <img
                            className={classes.image}
                            src={require("static/icons/step3.png")}
                            alt="Step3"

                        />
                        <Typography className={classes.steptext}>
                            {messages[locale].HowTo.step3text}
                        </Typography>
                    </Grid>
                </Grid>
                <motion.div
                    className={classes.button}
                    initial="hidden"
                    animate="visible"
                    whileHover={{ scale: 1.1 }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        fullWidth
                        href="/signinview"
                    >
                        {messages[locale].Start}
                    </Button>
                </motion.div>
            </Container>
        </section>
    );
}

export default HowTo
import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from 'modules/components/Typography';
import Button from 'modules/components/Button';
import { motion } from 'framer-motion';
import { messages } from 'translations/messages';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.dark,
        // backgroundColor: theme.palette.common.white,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5),
        },
        padding: theme.spacing(10),
    },
    //main title
    item1: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            fontSize: 28
        },
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        fontSize: 32
    },
    //main text
    item2: {
        fontSize: 18,
        color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        },
        marginTop: theme.spacing(3),
    },
    button: {
        width: '50%',
        maxWidth: 500,
        paddingTop: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(5),
        }
    }
}));

//animation
// const container = {
//     hidden: { opacity: 0 },
//     visible: {
//         opacity: 1,
//         // scale: 1,
//         transition: {
//             when: "beforeChildren",
//             staggerChildren: 0.1,
//             opacity: { duration: 1.5 }
//         }
//     }
// }

function WhatIsTavitt() {
    const classes = useStyles();
    const locale = useSelector(state => state.locale);
    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="subtitle2" className={classes.item1}>
                    {messages[locale].WhatIsTavitt.title}
                </Typography>
                <Typography className={classes.item2}>
                    {messages[locale].WhatIsTavitt.text1}
                </Typography>
                <Typography className={classes.item2}>
                    {messages[locale].WhatIsTavitt.text2}
                </Typography>
                <Typography className={classes.item2}>
                    {messages[locale].WhatIsTavitt.text3}
                </Typography>
                <motion.div
                    className={classes.button}
                    initial="hidden"
                    animate="visible"
                    whileHover={{ scale: 1.1 }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        fullWidth
                        href={locale === 'zh-CN' ? 'https://tavitt.co.jp/TAVITT_WP_Ch_ver2.01.pdf' : 'https://tavitt.co.jp/TAVITT_WP_En_ver2.01.pdf'}
                    >
                        {messages[locale].Tavitt}
                    </Button>
                </motion.div>
            </Container>
        </section>
    );
}

export default WhatIsTavitt
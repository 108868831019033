import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from 'modules/components/Button';
import Typography from 'modules/components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { motion } from 'framer-motion';
import Grid from '@material-ui/core/Grid';
// import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { messages } from 'translations/messages';
import { balance } from 'apis/Eth';
// import ProgressBar from 'modules/components/Progress';

const backgroundImage = (require('static/pictures/background.jpg'));
const styles = (theme) => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#fff', // Average color of the background image.
        backgroundPosition: 'center',
        // marginBottom: theme.spacing(10),
    },
    button: {
        // background: 'linear-gradient(60deg, #0380E0 30%, #005596 90%)',
        heith: 50,
        minWidth: 200,
        [theme.breakpoints.up('sm')]: {
            width: 300,
        },
        // autoCapitalize: false,
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10),
        },
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(10),
        display: 'flex',
        position: 'relative',
    },
    count: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10),
        },
    },
    more: {
        marginTop: theme.spacing(2),
    },
    barroot: {
        height: 15,
        borderRadius: 3,
        backgroundColor: "#626262"
    },
    bar: {
        borderRadius: 3,
        height: 15,
        backgroundColor: '#1a90ff',
    },
    statusbar: {
        borderRadius: 3,
        width: '80%',
        maxWidth: 600,
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 1),
        },
        padding: theme.spacing(0, 3),
    },
    itemtextsm: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px'
        },
        fontSize: '20px'
    },
    itemtextlx: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px'
        },
        fontSize: '30px'
    },
    buytext: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            marginBottom: theme.spacing(1),
        },
        marginBottom: theme.spacing(3),
        fontSize: '20px'
    }
});

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        // scale: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.1,
            opacity: { duration: 1.5 }
        }
    },
}

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    },
    transition: {
        opacity: { duration: 1 }
    }
}


function ProductHero(props) {
    const { classes } = props;
    const locale = useSelector(state => state.locale);
    const [progress, setProgress] = React.useState(2000000000000 / 1000000 / 30000);
    // const [timeLeft, setTimeLeft] = React.useState({
    //     days: '00',
    //     hours: '00',
    //     minutes: '00',
    //     seconds: '00'
    // });

    const calculateTimeLeft = () => {
        // let year = new Date().getFullYear();
        const difference = +new Date(`12/31/2020`) - +new Date();
        // console.log(difference);
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());


    React.useEffect(() => {
        // const timerBalance = setInterval(() => {
        //     setProgress(2000000000000 / 1000000 / 30000);
        //     // console.log(progress);
        //     // balance
        //     //     .then(res => setProgress(200000000 / 1000000 / 3000000))
        //     //     .catch(err => console.log(err))
        // }, 1000);

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearInterval(timer);
            // clearInterval(timerBalance);
        };
    });

    return (
        <motion.div
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
        >
            <ProductHeroLayout backgroundClassName={classes.background} >
                <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
                <motion.div className="item" variants={item}>
                    <Typography color="inherit" align="center" variant="h4" marked="center">
                        {messages[locale].Toptext}
                    </Typography>
                </motion.div>
                <Typography color="inherit" align="center" variant="h6" className={classes.h5}>
                    Purchase Status
                </Typography>
                <div className={classes.statusbar}>
                    <div className={classes.barroot}>
                        <div className={classes.bar} style={{ width: `${progress}%` }}>
                        </div>
                    </div>
                </div>
                <Typography color="inherit" align="center" variant="h2" className={classes.container}>
                    <Grid container>
                        <Grid item xs={3} sm={3} md={3}>
                            <div className={classes.item}>
                                <div className={classes.itemtextlx}>{timeLeft.days}</div>
                                <div className={classes.itemtextsm}>Days</div>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <div className={classes.item}>
                                <div className={classes.itemtextlx}>{timeLeft.hours}</div>
                                <div className={classes.itemtextsm}>Hours</div>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <div className={classes.item}>
                                <div className={classes.itemtextlx}>{timeLeft.minutes}</div>
                                <div className={classes.itemtextsm}>Minutes</div>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <div className={classes.item}>
                                <div className={classes.itemtextlx}>{timeLeft.seconds}</div>
                                <div className={classes.itemtextsm}>Seconds</div>
                            </div>
                        </Grid>
                    </Grid>
                </Typography>
                <Typography color="inherit" className={classes.buytext} align='center'>
                    100 USDT = 10000 TAVITT-R
                </Typography>
                <motion.div
                    className="container"
                    variants={container}
                    initial="hidden"
                    animate="visible"
                    whileHover={{ scale: 1.1 }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        className={classes.button}
                        href="/signinview"
                    >
                        {messages[locale].Start}
                    </Button>
                </motion.div>
            </ProductHeroLayout >
        </motion.div >
    );
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
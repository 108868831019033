import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from 'modules/components/Typography';
// import Button from 'modules/components/Button';
// import { motion } from 'framer-motion';
// import { messages } from 'translations/messages';
// import { useSelector } from 'react-redux';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.dark,
        backgroundColor: theme.palette.common.white,
    },
    container: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        display: 'flex',
        // position: 'relative',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
            margin: theme.spacing(0),
        },
    },
    subcontainer: {
        display: 'flex',
        padding: theme.spacing(6),
        // [theme.breakpoints.up('xs')]: {
        //     padding: theme.spacing(2),
        // },
    },
    addtiontop: {
        // paddingLeft: theme.spacing(10),
        // paddingRight: theme.spacing(10),
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(30),
        flexDirection: 'column',
        // display: 'flex',
        // position: 'relative',
    },
    item1: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    subcard1: {
        color: '#707070',
        marginBottom: theme.spacing(5),
    },
    image: {
        width: '100%',
        display: 'flex',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    image1: {
        display: 'none',
        width: '100%',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    }
});

function RoadMap(props) {
    const { classes } = props;
    // const locale = useSelector(state => state.locale.locale);
    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Typography variant="subtitle2" className={classes.item1}>
                    Roadmap
                </Typography>
                {/* <Typography variant="body2" className={classes.subcard1}>
                    The best luxury hotels,The best luxury hotels,The best luxury hotels,The best luxury hotels,The best luxury hotels,The best luxury hotels,The best luxury hotels,The best luxury hotels
                </Typography> */}
                <Container className={classes.subcontainer}>
                    <img
                        className={classes.image}
                        src={require("static/pictures/roadmap.png")}
                        alt="Roadmap"
                    />
                    <img
                        className={classes.image1}
                        src={require("static/pictures/roadmap1.png")}
                        alt="Roadmap"
                    />
                </Container>
            </Container>
        </section>
    );
}

RoadMap.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoadMap);
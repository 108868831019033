// --- Post bootstrap -----
import React from 'react';
// import Paper from 'modules/components/Paper';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from 'modules/components/Typography';
// import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Field, Form, FormSpy } from 'react-final-form';
import FormFeedback from 'modules/form/FormFeedback';
import RFTextField from 'modules/form/RFTextField';
import { messages } from 'translations/messages';
import { useDispatch, useSelector } from 'react-redux';
import FormButton from 'modules/form/FormButton';
import { motion } from 'framer-motion';
import { txid, wallet, required } from 'modules/form/validation';
// import { abi } from 'setups/abi';
// import InputDataDecoder from 'ethereum-input-data-decoder';
import AlertDialog from 'modules/components/Dialog';
// import { transaction } from 'apis/Eth';
// import { transactions } from 'apis/Auth';
// import { config } from 'setups/config';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { logout, resetpwact } from "apis/Auth";

import { tokenUpdate } from "redux/reducers/actions";
// import { FORM_ERROR } from "final-form";

// import VerticalTabs from 'modules/components/VerticalTabs';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            width: "95%",
            marginTop: 20,
        },
        display: 'flex',
        flexDirection: 'row',
        // maxWidth: 1000,
        width: "80%",
        maxHeight: '100vh',
        marginTop: 50,
        backgroundColor: '#fff',
        borderRadius: 5,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
    },
    right: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            marginLeft: 15
        },
        display: 'flex',
        flexDirection: 'column',
        // borderLeft: '3px solid #F5F5F5',
        paddingBottom: 50,
        // marginTop: 30,
        marginBottom: 30,
        paddingLeft: 50,
        // minWidth: 200
    },
    image: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            paddingTop: 60
        },
        height: 90,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 50
    },
    email: {
        paddingLeft: 20,
        paddingTop: 50,
        fontWeight: '600'
    },
    address: {
        paddingLeft: 20,
        paddingTop: 10,
        fontWeight: '600',
        color: theme.palette.secondary.main,
    },
    form: {
        width: '80%',
        // paddingLeft: 50,
        paddingTop: 30
    },
    welcome: {
        paddingTop: 60,
        paddingBottom: 30,
        // paddingLeft: 50,
        fontWeight: '600',
        fontSize: 24,
        color: theme.palette.secondary.main,
    },
    welcometext: {
        // paddingLeft: 50,
        color: '#707070'
    },
    button: {
        marginTop: 20
    },
    canclebutton: {
        marginTop: 20,
        backgroundColor: 'lightgray',
        padding: 8,
        color: 'gray'
    },
    down: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
        display: 'none',

    },
    // userinfo: {
    //     wordBreak: 'break-all',
    //     paddingBottom: theme.spacing(1),
    //     paddingTop: theme.spacing(1),
    // },
    userContainer: {
        width: '90%',
        // margin: theme.spacing(1),
    },
    error: {
        color: 'red'
    }
}));

export default function ResetPw() {
    const classes = useStyles();
    const locale = useSelector(state => state.locale);
    const token = useSelector(state => state.user.token);
    const history = useHistory();
    const dispatch = useDispatch();

    // const [userInfo, setUserInfo] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [disApply, setDisApply] = React.useState(false);
    const [reload, setReload] = React.useState(false);

    const [oldPassword, setOldPassword] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState(null);
    // const [formDis, setFormDis] = React.useState(false);

    // const [txidValue, setTxidValue] = React.useState(null);
    // const [walletValue, setWalletValue] = React.useState(null);
    // const [fromValue, setFromValue] = React.useState(null);
    // const [toValue, setToValue] = React.useState(null);
    // const [amount, setAmount] = React.useState(0);

    // const [internetError, setInternetError] = React.useState(null);

    // const decoder = new InputDataDecoder(abi);

    const validate = (values) => {
        const errors = required(['oldpassword', 'newpassword', 'comfirmnewpassword'], values);
        if (!errors.txid) {
            const txidError = txid(values.txid);
            if (txidError) {
                errors.txid = txid(values.txid);
            }
            const walletError = wallet(values.wallet);
            if (walletError) {
                errors.wallet = wallet(values.wallet);
            }
        }
        return errors;
    };

    const handleSubmit = async values => {
        console.log(values);
        setMessage('Are you sure to change your password?');
        setOldPassword(values.oldpassword);
        setNewPassword(values.newpassword);
        setOpen(true);
    };

    const handleApply = () => {
        resetpwact({
            token: token,
            oldpassword: oldPassword,
            newpassword: newPassword
        })
            .then(res => {
                // console.log(res);
                logout()
                    .then(() => {
                        dispatch(tokenUpdate(null));
                        // history.push('/signinview');
                        window.location.reload(false);
                    })
                    .catch((err) => console.log(err))
            }
            )
            .catch(err => {
                console.log(err);
                setSent(false);
                setMessage(err.response.data);
                // var msg = '';
                // if (err.message) { msg = JSON.stringify(err.message); }
                // else { msg = messages[locale].Errs[err.response.data.errors]; }
                // const msg = messages[locale].Errs[err.response.data.errors];
            })
    }

    return (
        <Box>
            <AlertDialog
                open={open}
                setOpen={setOpen}
                message={message}
                handleApply={() => handleApply()}
                disApply={disApply}
                setDisApply={setDisApply}
                reload={reload}
            />
            <Form
                onSubmit={handleSubmit}
                // subscription={{ submitting: true }}
                validate={validate}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className={classes.form}
                        noValidate
                    >
                        <Field
                            // autoComplete="txid"
                            // disabled={formDis}
                            autoFocus
                            component={RFTextField}
                            disabled={submitting || sent}
                            fullWidth
                            label={messages[locale].OldPassword}
                            margin="normal"
                            name="oldpassword"
                            required
                            size="small"
                            placeholder='Old Password'
                        />
                        <Field
                            // disabled={formDis}
                            fullWidth
                            size="small"
                            component={RFTextField}
                            disabled={submitting || sent}
                            required
                            name="newpassword"
                            // autoComplete="current-password"
                            label={messages[locale].NewPassword}
                            margin="normal"
                            placeholder='New Password'
                        />
                        <Field
                            // disabled={formDis}
                            fullWidth
                            size="small"
                            component={RFTextField}
                            disabled={submitting || sent}
                            required
                            name="comfirmnewpassword"
                            // autoComplete="current-password"
                            label={messages[locale].NewPassword}
                            margin="normal"
                            placeholder='New Password'
                        />
                        <FormSpy subscription={{ submitError: true }}>
                            {({ submitError }) =>
                                submitError ?
                                    <FormFeedback className={classes.feedback} error>
                                        {submitError}
                                    </FormFeedback>
                                    : null
                            }
                        </FormSpy>
                        <motion.div
                            animate={{
                                x: 0,
                                y: 0,
                                scale: 0.9,
                                rotate: 0,
                            }}
                            whileHover={{ scale: 1 }}
                        >
                            <FormButton
                                // disabled={formDis}
                                className={classes.button}
                                disabled={submitting || sent}
                                size="small"
                                color="secondary"
                                fullWidth
                            >
                                {submitting || sent ? `${messages[locale].Submitting} ` : `${messages[locale].Submit} `}
                            </FormButton>
                        </motion.div>
                        <motion.div
                            animate={{
                                x: 0,
                                y: 0,
                                scale: 0.9,
                                rotate: 0,
                            }}
                            whileHover={{ scale: 1 }}
                        >
                            <Button
                                // backgroundColor="black"
                                // disabled={formDis}
                                className={classes.canclebutton}
                                disabled={submitting || sent}
                                size="small"
                                onClick={() => history.push('/home')}
                                // color="secondary"
                                fullWidth
                            >
                                {messages[locale].Cancel}
                            </Button>
                        </motion.div>
                    </form>
                )}
            />
        </Box>
    )
}
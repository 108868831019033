import Web3 from 'web3';
import { abi } from 'setups/abi';
import { config } from 'setups/config'

let walletAddress = config.wallet;
let tokenAddress = config.tokenAddress;
var web3 = new Web3(Web3.givenProvider || config.infuraLink);
// var web3 = new Web3('https://rinkeby.infura.io/v3/76ca4d71f2ed48b7874c4ab3c6c2b245');
const contract = new web3.eth.Contract(abi, tokenAddress);

export const balance = contract.methods.balanceOf(walletAddress).call();

export const transaction = async (tranHash) => await web3.eth.getTransaction(tranHash, (err, result) => {
    if (err) return err
    return result
})


import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
    // const [open, setOpen] = React.useState(false);
    const [open, setOpen] = [props.open, props.setOpen];
    const handleApply = props.handleApply;
    const [disApply, setDisApply] = [props.disApply, props.setDisApply];

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
        setDisApply(false);
        if (props.reload) { window.location.reload(false); }
    };
    // const handleApply = () => {
    //     // setOpen(false);
    // };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleApply}
                        // color="primary" 
                        autoFocus
                        disabled={disApply}
                    >
                        Apply
                    </Button>
                    <Button
                        onClick={handleClose}
                        // color="primary" 
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

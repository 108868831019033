import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { usertrans } from 'apis/Auth';
import { useSelector } from 'react-redux';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableContainer: {
        display: 'flex',
        width: '100%',
        // backgroundColor: 'pink',
        // paddingRight: 40
    },
    amountCell: {
        paddingRight: 40
    }
});

function createData(index, txid, time, from, to, wallet, amount) {
    return {
        index,
        txid,
        time,
        from,
        to,
        wallet,
        amount
    };
}

function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {index}
                </TableCell>
                <TableCell align="center">{row.timeStamp}</TableCell>
                <TableCell align="center" className={classes.amountCell}>{row.amount}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Txid</TableCell>
                                        <TableCell>{row.txid}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>From</TableCell>
                                        <TableCell>{row.from}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To</TableCell>
                                        <TableCell>{row.to}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Wallet</TableCell>
                                        <TableCell>{row.wallet}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function CollapsibleTable() {
    // const data = props.data;
    const classes = useRowStyles();
    const token = useSelector(state => state.user.token);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        usertrans({ "token": token })
            .then(res => {
                setRows(res.data);
                console.log(res.data);
            })
            .catch(err => console.log(err))
    }, []);


    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>No.</TableCell>
                        <TableCell align="center">Apply Time</TableCell>
                        <TableCell align="center" className={classes.amountCell}>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows ? rows.map((row, index) => (
                        <Row key={row.txid} index={index + 1} row={row} />
                    )) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from 'modules/components/Typography';
// import TextField from 'modules/components/TextField';
// import Snackbar from 'modules/components/Snackbar';
import Button from 'modules/components/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { config } from 'setups/config';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        overflow: 'hidden',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        // backgroundColor: theme.palette.warning.main,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 3),
        },
        padding: theme.spacing(15, 5),
    },
    button: {
        width: '100%',
        maxWidth: 800,
        fontSize: 18,
        autoCapitalize: 'false',
        // marginLeft: theme.spacing(10),
        // marginRight: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            // width: '120%',
            // paddingLeft: theme.spacing(0),
            // paddingRight: theme.spacing(0),
            fontSize: 10
        }
    },
    subcard: {
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: theme.spacing(5),
        // color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        },
    },
    subcard1: {
        color: theme.palette.secondary.main
    },
});

function WalletInfo(props) {
    const { classes } = props;
    const [open, setOpen] = React.useState(false);
    const [copy, setCopy] = React.useState(config.wallet);


    // const handleOpen = () => {
    //     // setOpen(true);
    //     setCopy(config.wallet);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <Container className={classes.card}>
                    <Typography className={classes.subcard}>
                        Receive offers
                </Typography>
                    <Typography className={`${classes.subcard} ${classes.subcard1}`}>
                        ETH Wallet
                </Typography>
                    <CopyToClipboard
                        text={copy}
                        onCopy={() => setOpen(true)}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.button}
                            // autoCapitalize={false}
                            endIcon={<FileCopyIcon />}
                            onClick={() => setCopy(config.wallet)}
                        >
                            {config.wallet}
                        </Button>
                    </CopyToClipboard>
                </Container>
                <Snackbar
                    autoHideDuration={3000}
                    open={open}
                    onClose={handleClose}
                // message="Wallet address coppied"
                >
                    <MuiAlert
                        onClose={handleClose}
                        severity="success"
                        elevation={6}
                        variant="filled"
                    >
                        Wallet address coppied
                    </MuiAlert>
                </Snackbar>
            </Container>
        </section>
    );
}

WalletInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WalletInfo);
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Typography from 'modules/components/Typography';
import { messages } from 'translations/messages';
import { useDispatch, useSelector } from 'react-redux';
import { tokenUpdate, emailUpdate } from "redux/reducers/actions";

import Apply from 'modules/views/Apply';
import CollapsibleTable from 'modules/views/CollapsibleTable';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout } from 'apis/Auth';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
        // height: 224,
    },
    tabs: {
        [theme.breakpoints.down('xs')]: {
            orientation: 'horizontal',
            // height: 50,
            // paddingTop: 60
        },
        orientation: 'vertical',
        borderRight: `1px solid ${theme.palette.divider}`,
        marginTop: 30,
        marginBottom: 50
    },
    image: {
        [theme.breakpoints.down('xs')]: {
            height: 30,
            padding: 0
            // height: 50,
            // paddingTop: 60
        },
        height: 120,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 50
    },
    left: {
        // display: 'flex',
        [theme.breakpoints.down('xs')]: {
            borderRight: '0px',
        },
        borderRight: '3px solid #F5F5F5',
        // display: 'flex',
        flexDirection: 'column',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20
    },
    panels: {
        width: '100%',
        // backgroundColor: 'pink'
    },
    setting: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        flexDirection: 'row',
        alignItems: 'center'
    },
    email: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    address: {
        paddingLeft: 10
    },
    icontop: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
        }
    }
}));

export default function VerticalTabs() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale);
    const useremail = useSelector(state => state.user.email);
    const history = useHistory();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const logoutUser = () => {
        logout()
            .then(() => {
                dispatch(tokenUpdate(null));
                dispatch(emailUpdate(null));
                history.push('/');
                window.location.reload(false);
            })
            .catch((err) => console.log(err))
    }

    return (
        <div className={classes.root}>
            <Box className={classes.left}>
                <Box className={classes.icontop}>
                    <img
                        className={classes.image}
                        src={require("static/icons/tavittlogo-round.png")}
                        alt="Tavittlogo"
                    />
                    <Typography className={classes.address}>
                        {useremail}
                    </Typography>
                </Box>
                <Tabs
                    orientation='vertical'
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    <Tab label={messages[locale].ApplyForm} {...a11yProps(0)} />
                    <Tab label={messages[locale].Transactions} {...a11yProps(1)} />
                </Tabs>
                <Button
                    startIcon={<SettingsIcon />}
                    onClick={() => history.push('/resetpw')}
                >
                    {messages[locale].Setting}
                </Button>
                <Button
                    startIcon={<ExitToAppIcon />}
                    onClick={() => logoutUser()}
                >
                    {messages[locale].Logout}
                </Button>
            </Box>
            <TabPanel value={value} index={0} className={classes.panels}>
                <Apply />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.panels}>
                <CollapsibleTable />
            </TabPanel>
            {/* <TabPanel value={value} index={2} className={classes.panels}>
                Test
            </TabPanel> */}
        </div>
    );
}

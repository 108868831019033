import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import ProtectedRouteWithLayout from 'modules/ProtectedRouteWithLayout';
import RouteWithLayout from 'modules/RouteWithLayout';
import MainLayout from 'modules/layouts/Main';
// import ProtectedLayout from 'modules/layouts/Protected';

import Home from 'screens/Home';
import User from 'screens/User';
import ResetPassword from 'screens/ResetPassword';
import SignIn from 'screens/SignIn';
import SignUp from 'screens/SignUp';
import ForgotPassword from 'screens/ForgotPassword';
import Actived from 'screens/Actived';
// import Swap from 'screens/Swap';
// import Privacy from 'screens/Privacy';
// import Terms from 'screens/Terms';

import { useSelector } from 'react-redux';
import ResendActMail from 'screens/ResendActMail';

const Routes = () => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    // const { cookies: cookies, ...rest } = props
    return (
        <Switch>
            <RouteWithLayout
                component={Home}
                exact
                layout={MainLayout}
                path="/"
                top={true}
            />
            <RouteWithLayout
                component={SignIn}
                exact
                layout={MainLayout}
                path="/signinview"
            />
            <RouteWithLayout
                component={ResendActMail}
                exact
                layout={MainLayout}
                path="/resendactivemail"
                top={true}
            />
            <RouteWithLayout
                component={SignUp}
                exact
                layout={MainLayout}
                path="/signupview"
            />
            <RouteWithLayout
                component={ForgotPassword}
                exact
                layout={MainLayout}
                path="/forgotpassword"
            />
            {/* <RouteWithLayout
                component={Privacy}
                exact
                layout={MainLayout}
                path="/privacy"
            /> */}
            {/* <RouteWithLayout
                component={Terms}
                exact
                layout={MainLayout}
                path="/terms"
            /> */}
            <RouteWithLayout
                component={() => <div>resendemail</div>}
                exact
                layout={MainLayout}
                path="/resendemail"
            />
            <RouteWithLayout
                component={Actived}
                exact
                layout={MainLayout}
                path="/actived"
            />
            <ProtectedRouteWithLayout
                isAuthenticated={isAuthenticated}
                component={User}
                exact
                layout={MainLayout}
                path="/home"
            />
            <ProtectedRouteWithLayout
                isAuthenticated={isAuthenticated}
                component={ResetPassword}
                exact
                layout={MainLayout}
                path="/resetpw"
            />
            <RouteWithLayout
                component={() => <div>/not-found</div>}
                exact
                layout={MainLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch >
    )
}

export default Routes;
